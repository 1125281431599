import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logo from '../images/SerbWordWhite.svg';
import CryptoJS from 'crypto-js';
import Nav from '../components/Nav';
import * as XLSX from 'xlsx'; // Import the xlsx library

export default function SearchLeads({ user, TailSpin }) {
    const [sales, setSales] = useState([]);
    const [filteredSales, setFilteredSales] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]); // For multiple state selections
    const [allStatesSelected, setAllStatesSelected] = useState(false); // Track "All" selection
    const [recordAmount, setRecordAmount] = useState(0);
    const [campaignName, setCampaignName] = useState('');
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(false);

    const availableStates = ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT']; // Available states

    useEffect(() => {
        populateSearch();
    }, []);

    async function populateSearch() {
        try {
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const hmac = CryptoJS.HmacSHA256('', secretKey);
            const hmacDigest = hmac.toString(CryptoJS.enc.Hex);

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/leads`, {
                headers: {
                    'X-HMAC-Signature': hmacDigest,
                    'cache-control': 'no-cache',
                },
                withCredentials: true,
            });

            const sortedSales = response.data.sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate));
            setSales(sortedSales);  // Set the sales data
            setLoading(false);
        } catch (error) {
            console.error('Error:', error.message || 'Failed to connect to server');
        }
    }

    // Handle checkbox state selection
    const handleStateChange = (event) => {
        const state = event.target.value;
        const checked = event.target.checked;

        if (state === 'All') {
            if (checked) {
                setSelectedStates(availableStates);  // Select all states
                setAllStatesSelected(true); // Mark "All" as selected
            } else {
                setSelectedStates([]);  // Clear all selections
                setAllStatesSelected(false);
            }
        } else {
            if (checked) {
                setSelectedStates([...selectedStates, state]);
            } else {
                setSelectedStates(selectedStates.filter((selected) => selected !== state));
                setAllStatesSelected(false);  // Deselect "All" if a single state is deselected
            }
        }

        setSelectedStates(prevSelectedStates => {
            if (checked) {
                return [...prevSelectedStates, state];
            } else {
                return prevSelectedStates.filter(selected => selected !== state);
            }
        });
        
    };

    // Filter sales by selected states and remove records with dateProcessed already set
    useEffect(() => {
        if (sales.length > 0) {
            filterSales(sales);
        }
    }, [selectedStates, sales]);
    

    useEffect(() => {
        populateSearch();
    }, []);
    
    

    const filterSales = (allSales) => {
        if (allStatesSelected) {
            setFilteredSales(allSales.filter(sale => sale.processed === false)); // Check if sale is unprocessed
            
        } else if (selectedStates.length === 0) {
            setFilteredSales(allSales.filter(sale => sale.processed === false)); // Check if sale is unprocessed
        
        } else {
            const filtered = allSales.filter(sale => selectedStates.includes(sale.state) && sale.processed === false); // Exclude records that are processed
            console.log('Filtered sales:', filtered); // Log to debug
            setFilteredSales(filtered);
        }
    };
    
    // Define limitedRecords using filteredSales and recordAmount
    const limitedRecords = filteredSales.slice(0, recordAmount);

    const exportToExcel = (processedLeads) => {
        const currentDate = new Date().toLocaleDateString('en-GB'); // Current date in DD/MM/YYYY format

        const worksheet = XLSX.utils.json_to_sheet(processedLeads.map(lead => ({
            supplierCustomerId: lead.supplierCustomerId,
            requestDate: currentDate,  // Set the current date for every entry
            title: lead.title,
            firstName: lead.firstName,
            lastName: lead.lastName,
            gender: lead.gender,
            email: lead.email,
            address: lead.address,
            suburb: lead.suburb,
            state: lead.state,
            postCode: lead.postCode,
            phoneNumber: `${lead.phoneNumber}`,
            campaignName: campaignName,
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ProcessedLeads');
        XLSX.writeFile(workbook, `SPDR_${currentDate}_${recordAmount}.xlsx`);
    };

const CHUNK_SIZE = 100; // Number of leads per chunk, adjust based on your requirements

const handleProcessLeads = async () => {
    if (selectedStates.length === 0) {
        alert("Please select at least one state");
        return;
    }

    if (recordAmount > filteredSales.length) {
        alert("Don't have enough records");
        return;
    }

    // Grab the limited records that need to be processed
    const leadsToProcess = filteredSales.slice(0, recordAmount);

    const totalChunks = Math.ceil(leadsToProcess.length / CHUNK_SIZE); // Calculate total number of chunks

    for (let i = 0; i < totalChunks; i++) {
        const chunk = leadsToProcess.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE); // Create a chunk of leads

        try {
            // Send chunk to the server
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/leads/process`, {
                leads: chunk,  // Send the actual chunk to process
                campaignName,
                states: selectedStates,
                dateProcessed: new Date().toISOString(),
            });

            if (response.status === 200) {
                console.log(`Chunk ${i + 1} of ${totalChunks} uploaded successfully`);
            }
        } catch (error) {
            console.error(`Error uploading chunk ${i + 1} of ${totalChunks}:`, error.message);
            alert(`Error uploading chunk ${i + 1}. Stopping further uploads.`);
            break; // Stop further processing if there is an error
        }
    }

    // Refresh the data and export after all chunks are processed
    populateSearch(); // Refresh the data after processing
    exportToExcel(leadsToProcess); // Export the processed leads to Excel after processing
};

    

    return (
        <div className="container bg-gradient-to-br from-blue-950 to-blue-500 flex flex-col min-w-full min-h-screen justify-center items-center place-content-center">
            <Nav />
            <a href='/home' className='w-1/4 my-5'><img src={Logo} alt="Serbius Logo" className="" /></a>

            <div className="container flex flex-col justify-center bg-white p-3 m-3 rounded-2xl text-center md:min-w-min sm:w-11/12 shadow-lg shadow-blue-900">
                <h1 className="text-2xl font-sans font-bold pb-5">Search Records</h1>

                {loading ? (
                    <TailSpin visible={true} height="60" width="60" color="#15BCC8" ariaLabel="tail-spin-loading" />
                ) : (
                    <div className="container flex flex-col">
                        <div className="mb-4 container flex gap-5 justify-center">
                            <input
                                type="number"
                                placeholder="Records Amount"
                                onChange={(e) => setRecordAmount(e.target.value)}
                                className="placeholder:text-black p-2 text-lg text-center placeholder:text-center"
                            />
                            <input
                                type="text"
                                placeholder="Campaign Name"
                                onChange={(e) => setCampaignName(e.target.value)}
                                className="placeholder:text-black p-2 text-lg text-center placeholder:text-center"
                            />
                        </div>

                        <div className="mb-4 container flex flex-col justify-center">
                            <div className="flex justify-center gap-4">
                                <div>
                                    <input
                                        type="checkbox"
                                        value="All"
                                        checked={allStatesSelected}
                                        onChange={handleStateChange}
                                    />
                                    <label className="ml-2">All</label>
                                </div>
                                {availableStates.map((state) => (
                                    <div key={state}>
                                        <input
                                            type="checkbox"
                                            value={state}
                                            checked={selectedStates.includes(state)}
                                            onChange={handleStateChange}
                                        />
                                        <label className="ml-2">{state}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {filteredSales.length > 0 ? (
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-blue-50">
                                    <tr>
                                        <th className="px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider">
                                            Available Records
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    <tr key={filteredSales._id}>
                                        <td className="px-2 py-2 text-sm text-gray-600">{filteredSales.length}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <div className='text-red-500'>No sales found for the selected states.</div>
                        )}

                        <button onClick={handleProcessLeads} className="mt-4 px-4 py-2 bg-serbBlue w-1/4 text-white rounded">
                            Process
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
