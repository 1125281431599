import React, { useState } from 'react';
import Papa from 'papaparse';
import Nav from '../components/Nav';
import Logo from '../images/SerbWordWhite.svg';
import axios from 'axios';

export default function CsvUploader({user}) {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [propertyMapping, setPropertyMapping] = useState({});
  const [apply, setApply] = useState(false)
  const [message, setMessage] = useState(null)

  const requiredHeaders = [
    'firstName',
    'address',
    'suburb',
    'state',
    'postCode',
    'phoneNumber',
  ];
  
  const optionalHeaders = ['email', 'gender', 'title', 'lastName'];
  const presetHeaders = [...requiredHeaders, ...optionalHeaders]; 
  

  const handleOnChange = (e) => {
    setFile(e.target.files[0]); // Save selected file to state
  };

  const CHUNK_SIZE = 500; // Number of records per chunk

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  
  const uploadLeads = async () => {
    const totalChunks = Math.ceil(data.length / CHUNK_SIZE);
  
    if (!apply) {
      alert("Please apply headers");
      return;
    }
  
    for (let i = 0; i < totalChunks; i++) {
      const chunk = data.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/leads/add`, chunk);
  
        if (response.status === 200) {
          console.log(`Chunk ${i + 1} of ${totalChunks} uploaded successfully`);
          setMessage(`Uploading ${i + 1} of ${totalChunks}`);
        }
  
        // Introduce a delay between chunks to reduce load on the backend
        await delay(1000); // Wait 500ms between each request (adjust as needed)
  
      } catch (err) {
        console.error(`Error uploading chunk ${i + 1} of ${totalChunks}:`, err.message);
        alert(`Error uploading chunk ${i + 1}`);
        break;
      }
    }
  
    setMessage(null);
    alert("Leads uploaded successfully");
  };
  

  


  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      Papa.parse(file, {
        header: true, // Automatically converts CSV to JSON objects
        complete: function (results) {
          setData(results.data); // Save parsed data to state
          const initialMapping = Object.keys(results.data[0]).reduce((acc, key) => {
            acc[key] = key; // Initialize with the same property names
            return acc;
          }, {});
          setPropertyMapping(initialMapping); // Set the property names mapping
        },
      });
    } else {
      console.log('No file selected');
    }
  };

  // Function to handle property name change (only changing it once for all objects)
  const handlePropertyNameChange = (oldProp, newProp) => {
    setPropertyMapping((prevMapping) => ({
      ...prevMapping,
      [oldProp]: newProp,
    }));
  };

  // Function to apply the property renaming to all data objects
  // Function to apply the property renaming to all data objects
  const applyPropertyRenaming = () => {
    const updatedData = data.map((row) => {
      const updatedRow = {};
      
      // Only include mapped fields (both required and optional, if mapped)
      Object.keys(row).forEach((csvHeader) => {
        const newKey = propertyMapping[csvHeader]; // Get the new key from mapping
        if (newKey && (requiredHeaders.includes(newKey) || optionalHeaders.includes(newKey))) {
          updatedRow[newKey] = row[csvHeader]; // Map the old key to the new one
        }
      });
  
      return updatedRow;
    });
    
    setApply(true);
    setData(updatedData); // Update the state with renamed properties
  };
  


  // Function to render the form for renaming properties
  // Function to render the form for mapping CSV headers to preset headers
const renderEditableForm = () => {
  return (
    <div>
      <div className='mb-4 container grid grid-cols-2 gap-5'>
        {Object.keys(propertyMapping).map((csvHeader, keyIndex) => (
          <div key={keyIndex} className='flex flex-col'>
            <label className='text-gray-700 text-sm font-bold mb-2'>
              CSV Header: {csvHeader}
            </label>
            <select
              value={propertyMapping[csvHeader]}
              onChange={(e) => handlePropertyNameChange(csvHeader, e.target.value)}
              className='border rounded p-2'
            >
              <option value="">-- Don't Map --</option>
              {presetHeaders.map((presetHeader, presetIndex) => (
                <option key={presetIndex} value={presetHeader}>
                  {presetHeader}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>

      

      <div className='container flex justify-between'>
        <button
          className='bg-blue-500 text-white p-2'
          onClick={applyPropertyRenaming}
        >
          Apply Headers
        </button>

        <button
          className='bg-serbBlue text-white p-2'
          onClick={uploadLeads}
        >
          Upload to DB
        </button>
      </div>
    </div>
  );
};




  return (
    <div className='min-w-full container flex flex-col bg-slate-100 min-h-screen helv'>
        <div className="bg-gradient-to-br md:px-20 sm:px-2 from-blue-950 to-blue-900 container flex flex-row min-w-full h-32 justify-between items-center place-content-center">
        <a href='/home' className="md:w-1/4 sm:w-1/2 my-5"><img src={Logo} alt='Serbius Logo'  /></a>
        <Nav user={user}/>
      </div>

      <div className='md:px-20 sm:px-2'>

        <div className='container flex  justify-between py-10'>
            <div>
                <h2 className='text-lg helv-med w-max'>Upload .csv file</h2>
            </div>

            <div className=' flex '>
                <input
                id={'csvFileInput'}
                type='file'
                accept='.csv'
                onChange={handleOnChange}
                className='m-0'
                />
                <button
                className='bg-blue-500 text-white p-2 hover:bg-blue-700'
                onClick={handleOnSubmit}
                >
                Map Headers
                </button>
            </div>
        </div>
        

        {file && (
            <div> 
                <div className='w-full mt-4'>
            {renderEditableForm()} {/* Renders the form to manually change property names */}
        </div>

        <div className='my-4'>
            <h3 className='text-lg'>Updated Data:</h3>
            <div className='container grid grid-cols-4'> 
            {Object.keys(data).map((key, index) => (
            <pre key={index} className='mb-2 text-xs'>
                <strong>{key}:</strong> {typeof data[key] === 'object' ? JSON.stringify(data[key], null, 2) : data[key]}
            </pre>
        ))}
            </div>
        </div>
            </div>
        )}

        
      </div>


        {message && (
          <div className='w-full  fixed top-10 '>
          <div className='bg-white h-full w-80 m-auto text-center p-10 text-lg helv-med'>{message}</div>
        </div>
        )}
      
    </div>
  );
}
