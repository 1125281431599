import React, {useEffect, useState} from "react";
import axios from "axios";
import CryptoJS from 'crypto-js';
import SuperAdminDash from "./SuperAdminDash";
import AdminDash from "./AdminDash";
import CampaignAdmin from "./CampaignAdmin";


export default function Dashboard({ user, TailSpin }) {



    return (

      <div>
      {user.accountType === 0 ? (
        <h1 className='text-black'>You don't have access to this site</h1>
      ) : (
        <div>
          {user.accountType === 2 ? (
            <SuperAdminDash user={user} />
          ) : (
            <div>
              {user.accountType === 3 ? (
                <CampaignAdmin user={user} />
              ) : (
                <AdminDash user={user} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
    );
}
