import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/SerbWordWhite.svg';
import axios from 'axios';
import AddAgentForm from '../components/AddAgentForm';
import AddAdminForm from '../components/AddAdminForm';
import AddAgentAdminForm from '../components/AddAgentAdminForm.';
import Nav from '../components/Nav';
import EditForm from '../components/EditForm';


export default function Profile() {
  const [user, setUser] = useState({members: []});
  const navigate = useNavigate();
  const [agentForm, setAgentForm] = useState(false)
  const [agentAdminForm, setAgentAdminForm] = useState(false)
  const [showAddAdmin, setShowAddAdmin] = useState(false)
  const [selectedUser, setSelectedUser] = useState([]);
  const [AdminAdd, setAdminAdd] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [newAdmin, setNewAdmin] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    team: '',
    campAdmin: false,
    projectAdmin: '',
    accountType: 1,
    members: [{ firstName: '', lastName: '', password: '', username: '', accountType: 0  }], // Members array with agent details
  });
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [fade, setFade] = useState('fade-out');
  const [campAdmin, setCampAdmin] = useState(false)

  

  const showMessage = () => {
    setAdminAdd(true);
    setFade('fade-in'); // Start fading in

    setTimeout(() => {
      setFade('fade-out'); // Start fading out after 3 seconds
      setTimeout(() => {
        setAdminAdd(false); // Completely remove the component after fade-out
      }, 500); // Allow 0.5 seconds for the fade-out transition
    }, 3000); // Keep the message visible for 3 seconds
  };

  const editFormOpen = (user) => {
    setEditForm(true)
    setSelectedUser(user)
  }

  const fetchUserData = async (userId) => {
    try {
        // Fetch the user data including populated members and their members
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            }
        });

        // Set the user data (which already includes members and their agents)
        const userData = response.data;
        setUser(userData);
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};


// Use in your useEffect
useEffect(() => {
    fetchUserData(user._id);
}, [user._id, user]);



  useEffect(() => {
    // Retrieve user info from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate('/'); // Redirect to sign-in if user info is not found
    }
  }, [navigate]);  

  const handleAddAdminClick = () => {
    setShowAddAdmin(!showAddAdmin);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({
        ...prev,
        [name]: value, // Dynamically update the correct property
    }));
};


  // Handle member input changes
  const handleMemberChange = (index, e) => {
    const { name, value } = e.target;
    const updatedMembers = [...newAdmin.members];
    updatedMembers[index] = { ...updatedMembers[index], [name]: value };
    setNewAdmin({ ...newAdmin, members: updatedMembers });
  };

  // Add a new member field
  const addMemberField = () => {
    setNewAdmin({ ...newAdmin, members: [...newAdmin.members, { firstName: '', lastName: '', username: '', accountType: 0, password: '' }] });
  };

  // Remove a member field
  const removeMemberField = (index) => {
    const updatedMembers = newAdmin.members.filter((_, i) => i !== index);
    setNewAdmin({ ...newAdmin, members: updatedMembers });
  };

  const handleAddAdminSubmit = async (e) => {
    e.preventDefault();
  
    if (!newAdmin.team) {
      alert('Please enter a team name.');
      return;
    }
  
    // Prepare the admin data
    const adminData = {
      firstName: newAdmin.firstName,
      lastName: newAdmin.lastName,
      email: newAdmin.email,
      team: newAdmin.team,
      password: newAdmin.password,
      accountType: newAdmin.campAdmin ? 3 : 1,
      projectAdmin: newAdmin.campAdmin ? newAdmin.projectAdmin : '',
      members: newAdmin.members.map((member) => ({
        ...member,
        team: newAdmin.team,
        accountType: 0,
      })),
      currentUserId: user._id,
    };
    
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/addAdmin`,
        adminData
      );
  
      if (response.status === 200 || response.status === 201) {
        setShowAddAdmin(false);
        showMessage();
        setTimeout(() => {
          setAdminAdd(false);
        }, 2000);
  
        // Reset the form
        setNewAdmin({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          team: '',
          campAdmin: false,
          projectAdmin: '',
          members: [],
        });
  
        // Update the UI
        setUser((prevUser) => ({
          ...prevUser,
          members: [...prevUser.members, response.data],
        }));
      } else {
        alert(response.data.error || 'Failed to add new admin');
      }
    } catch (error) {
      console.error('Error creating admin and agents:', error);
      alert('Error creating admin and agents: ' + (error.response?.data?.message || error.message));
    }
  };
  
  



// Function to handle removing an agent
const handleRemoveAdmin = async (superAdminId, adminId) => {
  const confirmed = window.confirm("Are you sure you want to delete this admin and all their agents?");
  
  if (!confirmed) return;  // Exit if the user cancels the confirmation

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/removeAdmin/${superAdminId}/${adminId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      const result = await response.json();

      if (response.ok) {
        alert('Admin and their agents were deleted successfully.');
        // Optionally, refresh the data or update the UI
      } else {
        alert(`Error: ${result.message}`);
      }
    } else {
      const text = await response.text(); // Read the response as text
      alert(`Unexpected response format: ${text}`);
    }
  } catch (error) {
    console.error("Error deleting admin:", error);
    alert("Server error. Please try again later.");
  }
};

const handleRemoveAgent = async (adminId, agentId) => {
  if (window.confirm('Are you sure you want to delete this agent?')) {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/users/removeAgent/${adminId}/${agentId}`);
      if (response.status === 200) {
        // Update UI to reflect deletion
        setUser(prevUser => ({
          ...prevUser,
          members: prevUser.members.map(admin => 
            admin._id === adminId 
              ? { ...admin, members: admin.members.filter(agent => agent._id !== agentId) }
              : admin
          ),
        }));
      } else {
        console.error('Failed to delete agent:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
    }
  }
};



const handleAddAgent = (adminId) => {
  setSelectedAdminId(adminId);
  setAgentAdminForm(true); // Open the form
};

  return (
    <div className='min-w-full container flex flex-col bg-slate-100 min-h-screen helv'>
      <div className="bg-gradient-to-br md:px-20 sm:px-2 from-blue-950 to-blue-900 container flex flex-row min-w-full h-32 justify-between items-center place-content-center">
      <a href='/home' className="md:w-1/4 sm:w-1/2 my-5"><img src={Logo} alt='Serbius Logo'  /></a>
        <Nav user={user}/>
      </div>

      <div className='md:px-20 sm:px-2 py-5 helv'>
        {user.accountType === 0 ? (
          <div>
            You don't have access to this site.
          </div>
        ):
        (
          <div>
            {user && (
            <div className=''>
                <div className='w-full  container flex flex-col gap-10'>
                    <div className='container flex justify-between'> 
                        <div>
                            <h1 className='text-5xl helv-med  text-serbBlue '>{user.firstName} {user.lastName}</h1>
                            <h2 className='text-lg helv uppercase text-slate-500 '>{user.accountType === 2 ? 'Super Admin' : 'Admin'}</h2>
                        </div>

                        <div className='rounded-full border-solid bg-serbBlue hover:bg-blue-500 hover:cursor-pointer border-2 p-2 h-min'>
                            <button 
                            onClick={() => editFormOpen(user)}
                            className='container flex gap-2 justify-center items-center'> 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className='container flex gap-2 flex-col'> 
                    <div className='container flex justify-between'> 
                        <div>
                            <h2 className='text-lg  helv'>Email:</h2>
                        </div>

                        <div className='helv-light text-lg'>
                            <p>{user.email}</p>
                        </div>
                    </div>

                    

                    <div className='container flex justify-between'> 
                        <div>
                            <h2 className='text-lg helv'>Team Name:</h2>
                        </div>

                        <div className='helv-light text-lg'>
                            <p>{user.team}</p>
                        </div>
                    </div>

        {/*UPLOAD CSV FILE START _________________________________________________________________________ */}

                    
                    </div>
                </div>
                

                <div className='pt-10'>
                        <h1 className='text-3xl helv-med   '>
                        {user.accountType === 2 ? 'Manage Admins' : 'Manage Agents'}
                        </h1>
                </div>

                

                <div className='pt-7 overflow-scroll'>
                  {user.accountType === 2 ? (
                    <div className='container flex flex-col gap-5'>
                    <div className='overflow-x-scroll'>
                    <table  className='overflow-x-scroll divide-y w-full divide-gray-200 pt-10'>
                    <thead className='bg-serbBlue helv-med'>
                        <tr key="table">
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'></th>

                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'>Team Name</th>
                        <th className='px-2 py-3 text-center w-min text-xs font-medium text-slate-50 uppercase tracking-wider'>Campaign Admin?</th>

                            <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'>Email</th>
                            <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'>Name</th>
                            <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'>Agents</th>
                            <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'></th>
                        </tr>
                    </thead>
                    <tbody className=" divide-y divide-gray-200  text-center">

                  {/*SUPER ADMIN ADMINS START ______________________ */}
                    {user.members.length > 0 ? (
        user.members.filter((agent) => agent.accountType === 1 || agent.accountType === 3)
        .map((admin) => (
        <tr className='' key={admin._id}>
          <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'> 
            <button 
              onClick={() => editFormOpen(admin)}
              className='rounded-full bg-serbBlue text-white hover:bg-blue-500 p-2 text-center'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="size-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
              </svg>
            </button>
          </td>
            <td className='px-2 py-3  whitespace-nowrap text-sm text-gray-600'>{admin.team}</td>
            <td className='px-2 py-3 w-min  whitespace-nowrap text-sm text-gray-600'>{admin.accountType === 3 ? "Yes" : "No"}</td>

            <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{admin.email}</td>
            <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{admin.firstName} {admin.lastName}</td>
            

            <td > 
              
                {admin.members?.length > 0 ? (
                      
                        
                        <tbody className='container flex flex-col divide-y w-full justify-center items-center h-max gap-1 '>
                            {admin.members.map((agent) => (
                              
                                <tr className='container flex w-full justify-center gap-1 ' key={agent._id}>
                                  
                                    <td className='px-2 py-3 w-full  text-center whitespace-nowrap text-sm text-gray-600'>
                                        {agent.firstName} {agent.lastName}
                                    </td>
                                    <td className='px-2 py-3  whitespace-nowrap text-sm text-gray-600'>
                                        <button 
                                            onClick={() => handleRemoveAgent(admin._id, agent._id)} 
                                            className='rounded-full bg-red-400 text-white hover:bg-red-800'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        </button>
                                    </td>
                                    
                                </tr>
                            ))}
                            <button onClick={() => handleAddAgent(admin._id)} className='bg-serbBlue bg-opacity-50 hover:bg-opacity-100 w-1/2 mb-5 text-white'>Add Agent</button>
                        </tbody>
                    
                ) : (
                  <tr className='container flex flex-col'>
                  <td className='x-2 py-3 text-sm  text-red-400'>
                    No Agents  
                    </td>
                    <td>
                    <button onClick={() => handleAddAgent(admin._id)} className='bg-serbBlue bg-opacity-50 hover:bg-opacity-100 w-1/2 mb-5 text-white'>Add Agent</button>

                    </td>
                    </tr>
                    
                )}
            </td>

            <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>
                <button 
                    onClick={() => handleRemoveAdmin(user._id, admin._id)} 
                    className='rounded-full p-1 bg-red-500 text-white hover:bg-red-800'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                </button>
            </td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="5" className='px-2 py-3 text-center text-sm text-gray-600'>No Admins Added</td>
    </tr>
)}
                    </tbody>
                </table>
                <div className='w-full text-right pt-10'> 
                <button onClick={handleAddAdminClick} className='p-1 w-1/4 py-2  bg-serbBlue text-white'>Add New Admin</button>
                </div>
                </div>



                {/*START OF SUPER ADMIN AGENTS ____________________________________________________________ */}
                <h1 className='text-3xl helv-med'>Manage Agents</h1>
                <div className='overflow-x-scroll'>
                <table className='overflow-x-scroll divide-y w-full divide-gray-200 pt-10'>
                <thead className='bg-serbBlue'>
                <tr className='helv-med'>
                <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'></th>

                <th className='px-2 py-3 text-center text-xs  text-slate-50 uppercase tracking-wider'>Team Name</th>
                    <th className='px-2 py-3 text-center text-xs  text-slate-50 uppercase tracking-wider'>Username</th>
                    <th className='px-2 py-3 text-center text-xs  text-slate-50 uppercase tracking-wider'>Name</th>
                    <th className='px-2 py-3 w-10 text-center text-xs  text-slate-50 uppercase tracking-wider'></th>

                </tr>
            </thead>
            <tbody className=" divide-y divide-gray-200 text-center">
             {user.members?.length > 0 ? (
              user.members.filter((agent) => agent.accountType === 0)
                .map((agent) => (
                <tr key={agent._id} className='helv'>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'> 
                    <button 
                    onClick={() => editFormOpen(agent)}
                      className='rounded-full bg-serbBlue text-white hover:bg-blue-500 p-1 w-5 h-5 text-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="size-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>
                    </button></td>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'>{agent.team}</td>

                  <td className='px-2  items-center py-3 whitespace-nowrap text-sm text-gray-600'>{agent.username}</td>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'>{agent.firstName} {agent.lastName}</td>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'> 
                    <button 
                      onClick={() => handleRemoveAgent(user._id, agent._id)} 
                      className='rounded-full bg-red-500 text-white hover:bg-red-800 w-5 h-5'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                    </button>
                    </td>

                </tr>
                
              ))
              

            ) : (
              <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>No Agents</td>
            )}             
            </tbody>
        </table>
        <div className='w-full text-right pt-10'> 
                <button onClick={() => setAgentForm(true)} className='p-1 w-1/4 py-2  bg-serbBlue text-white'>Add New Agent</button>
            </div>
        </div>

        {/*END OF SUPER ADMIN AGENTS ____________________________________________________________ */}
                </div>
                  ) : (
                    <div className='overflow-x-scroll'>
                    <table className='overflow-x-scroll divide-y w-full divide-gray-200 pt-10'>
                    <thead className='bg-serbBlue'>
                <tr className='helv-med'>
                <th className='px-2 py-3 text-center text-xs font-medium text-slate-50 uppercase tracking-wider'></th>

                <th className='px-2 py-3 text-center text-xs  text-slate-50 uppercase tracking-wider'>Team Name</th>
                    <th className='px-2 py-3 text-center text-xs text-slate-50 uppercase tracking-wider'>Username</th>
                    <th className='px-2 py-3 text-center text-xs  text-slate-50 uppercase tracking-wider'>Name</th>
                    <th className='px-2 py-3 w-10 text-center text-xs  text-slate-50 uppercase tracking-wider'></th>

                </tr>
            </thead>
            <tbody className=" divide-y divide-gray-200 text-center">
             {user.members?.length > 0 ? (
              user.members.map((agent) => (
                <tr key={agent._id}>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'> 
                    <button 
                    onClick={() => editFormOpen(agent)}
                      className='rounded-full bg-serbBlue text-white hover:bg-blue-400 p-1 w-5 h-5 text-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="size-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>
                    </button></td>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'>{agent.team}</td>

                  <td className='px-2  items-center py-3 whitespace-nowrap text-sm text-gray-600'>{agent.username}</td>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'>{agent.firstName} {agent.lastName}</td>
                  <td className='px-2  py-3 whitespace-nowrap text-sm text-gray-600'> 
                    <button 
                      onClick={() => handleRemoveAgent(user._id, agent._id)} 
                      className='rounded-full bg-red-500 text-white hover:bg-red-800 w-5 h-5'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                    </button></td>

                </tr>
              ))
            ) : (
              <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>No Agents</td>
            )} 


            
            </tbody>
        </table>

        <div className='w-full text-right pt-10'> 
                <button onClick={() => setAgentForm(true)} className='p-1  bg-serbBlue hover:bg-blue-400 text-white'>Add New Agent</button>
            </div>

            </div>
                  )}
                

            
        </div>
          
          </div>
        )}
          </div>
        )}

        {/* Add Admin Popup */}
        {showAddAdmin && (
              <AddAdminForm 
              handleAddAdminSubmit={handleAddAdminSubmit}
              handleInputChange={handleInputChange}
              handleMemberChange={handleMemberChange}
              newAdmin={newAdmin}
              addMemberField={addMemberField}
              setShowAddAdmin={setShowAddAdmin}
              removeMemberField={removeMemberField}
              campAdmin={campAdmin}
              setCampAdmin={setCampAdmin}
              setNewAdmin={setNewAdmin}
              />
            )}

      </div>

            {agentAdminForm && (
              <AddAgentAdminForm setAgentAdminForm={setAgentAdminForm} adminId={selectedAdminId} />
            )}
            
            {agentForm && (
              <AddAgentForm setAgentForm={setAgentForm} user={user} />
            )}
      
            {editForm && (
              <EditForm 
              setEditForm={setEditForm} 
              selectedUser={selectedUser} 
              fetchUserData={fetchUserData} 
              user={user}
              
              />
            )}
            

            {AdminAdd && (
              <div className={`fixed top-0 min-w-full h-screen container flex justify-center items-start ${fade}`}>
                 <div className='mt-5 w-96 h-32 container flex items-center bg-white rounded-lg shadow-lg'> 
                    <h3 className='text-lg helv text-center m-auto place-content-center'>Successfully added Admin and their Agents!</h3>
                 </div>
              </div>
            )}
    </div>
  );
}
