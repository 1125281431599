import React, {useState, useEffect} from "react";
import axios from "axios";
import { TailSpin } from 'react-loader-spinner';

export default function AdminDash({user}) {
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);

    const sortedTeam = submissions
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10); // Take the first 10 items after sorting

    const fetchAdminSales = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales/team`, {
                    params: { userId } // Fetch only team submissions based on userId
            });
            setSubmissions(response.data); // Ensure you're setting team submissions here
            setLoading(false);
        } catch (error) {
            console.error('Error fetching team submissions:', error);
            setLoading(false);  // Stop loading if there's an error
        }
    };

    useEffect(() => {
        if (user._id) {
            fetchAdminSales(user._id);  // Fetch only team sales for the current admin
        }
    }, [user._id, submissions]);  // Removed unnecessary sales state

    return(
        <div className=''>
            <div className='container flex min-w-full h-full flex-col justify-center items-center'>
             {loading ? (
               <div className='container flex min-w-full justify-center'>
                 <TailSpin
                   visible={true}
                   height="60"
                   width="60"
                   color="#15BCC8"
                   ariaLabel="tail-spin-loading"
                   radius="1"
                   strokeWidth={"5px"}
                 />
               </div>
             ) : (
                <div>
                <h1 className='text-2xl font-sans font-bold pb-5'>Last 10 {user.team} Team Submissions!</h1>
                <div className='sm:overflow-x-scroll'>
                <table className='overflow-x-scroll divide-y divide-blue-300 w-full '>
                <thead className='bg-blue-50'>
                    <tr>
                    <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                        <th className='px-2 py-3 text-center  text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">
                    {sortedTeam.length > 0 ? (
                        sortedTeam.map((submission) => (
                            <tr key={submission._id}>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{submission.supplierCustomerId}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{new Date(submission.submitDate).toLocaleDateString()}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{submission.firstName} {submission.lastName}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{submission.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>${submission.pledge}</td>
                                <td className='px-2 py-3 text-sm max-w-md  break-words text-gray-600'>{submission.outcome}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{submission.agentName}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className='px-2 py-3 text-center text-sm text-gray-600'>No Data</td>
                        </tr>
                    )}
                </tbody>
                        </table>
                        </div>
                </div>
             )}
            </div>
        </div>
    )
}
