import React, {useState} from "react";
import axios from "axios";

export default function EditForm({setEditForm, selectedUser, fetchUserData, user = {}}) {
    const [formData, setFormData] = useState({
        firstName: selectedUser?.firstName || '',
        lastName: selectedUser?.lastName || '',
        email: selectedUser?.email || '',
        team: selectedUser?.team || '',
        password: '',
        newPassword: ''
      });
    
      // Handle form input changes
      const handleFormChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      // Handle form submission with password confirmation
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        if (!selectedUser || !selectedUser._id) {
            alert("User data is missing.");
            return;
        }
    
        try {
            // Prepare the data to be updated
            const updatedData = {};
            const updateMembers = [];
    
            // Include fields in updatedData that have changed
            if (formData.firstName !== selectedUser.firstName) updatedData.firstName = formData.firstName;
            if (formData.lastName !== selectedUser.lastName) updatedData.lastName = formData.lastName;
            if (formData.email !== selectedUser.email) updatedData.email = formData.email;
            if (formData.team !== selectedUser.team) {
                updatedData.team = formData.team;
                
                // Check if the selectedUser is a normal admin
                if (selectedUser.accountType === 1) {
                    // Prepare the data to update members' team names
                    updateMembers.push(
                        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/update-members-team`, {
                            userId: selectedUser._id,
                            newTeam: formData.team
                        })
                    );
                }
            }
    
            // Only verify the current password if a new password is being set
            if (formData.newPassword) {
                // Verify the current password
                const passwordVerification = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/verify-password`, {
                    userId: selectedUser._id,
                    password: formData.password
                });
    
                if (passwordVerification.data.message !== 'Password verified') {
                    alert("Current password does not match");
                    return;
                }
    
                // If verification is successful, include the new password in the update data
                updatedData.newPassword = formData.newPassword;
            }
    
            // Proceed to update user details if there's anything to update
            if (Object.keys(updatedData).length > 0) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/update/${selectedUser._id}`, updatedData);
                alert("User updated successfully");
    
                // Wait for all member updates to complete
                if (updateMembers.length > 0) {
                    await Promise.all(updateMembers);
                }
    
                setEditForm(false);  // Close the form after successful submission
                fetchUserData(user._id);  // Re-fetch the user data to reflect changes
            } else {
                alert("No changes detected.");
            }
        } catch (error) {
            console.error("Error updating user:", error);
            alert("Error updating user details");
        }
    };
    
    
    
    
    
    
    
    
    return(
        <div className="conatiner flex bg-black bg-opacity-50 min-w-full font-sans min-h-full fixed justify-center items-center p-5">
            {selectedUser ? (
                <div className="bg-white md:min-w-3/4 sm:w-full max-h-full gap-5 container flex flex-col  rounded-xl p-5 overflow-y-scroll">
                <h1 className="text-xl  font-bold">Edit Information</h1>
                <form onSubmit={handleFormSubmit}>
                    <div className="container flex min-w-full gap-5 flex-col">
          <div className="w-full container flex md:flex-row sm:flex-col  gap-2">
            <input
            className="bg-gray-100 w-full p-2 placeholder:text-gray-600 text-center"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleFormChange}
            />
    
            <input
                className="bg-gray-100 w-full p-2 placeholder:text-gray-600 text-center"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleFormChange}
            />
          </div>
          
          <div className="w-full container flex md:flex-row sm:flex-col  gap-2">
            <input
            className="bg-gray-100 w-full p-2 placeholder:text-gray-600 text-center"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleFormChange}
            />
          </div>
          
            {selectedUser.accountType === 0 ? (
                null
            ):
            (<div className="w-full container flex md:flex-row sm:flex-col  gap-2">
                <input
                  className="bg-gray-100 w-full p-2 placeholder:text-gray-600 text-center"
                  type="text"
                  name="team"
                  placeholder="Team Name"
                  value={formData.team}
                  onChange={handleFormChange}
                />
              </div>)}
          
          <div className="w-full container flex md:flex-row sm:flex-col  gap-2">
            <input
            className="bg-gray-100 p-2 w-full placeholder:text-gray-600 text-center"
              type="password"
              name="password"
              placeholder="Current Password"
              value={formData.password}
              onChange={handleFormChange}
            />
          
            <input
            className="bg-gray-100 w-full p-2 placeholder:text-gray-600 text-center"
              type="password"
              name="newPassword"
              placeholder="New Password"
              value={formData.newPassword}
              onChange={handleFormChange}
            />
          </div>
        
            <div className="w-full container justify-between flex md:flex-row sm:flex-col  gap-2">
                <button className="text-red-500 font-bold hover:text-red-800" type="button" onClick={() => setEditForm(false)}>Cancel</button>
                <button className="bg-purple-500 p-2 text-white" type="submit">Save Changes</button>           
            </div>
          </div>
          
        </form>
                </div>
            ):
            (<div>
                <p>User data is not available.</p>
                </div>)}
        </div>
    )
}