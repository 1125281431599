import React, {useState, useEffect} from "react";
import CryptoJS from "crypto-js";
import axios from "axios";

export default function CampaignAdmin({user}){

    const [sales, setSales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [salesLoaded, setSalesLoaded] = useState(false);

    async function populateHome() {
        try {
          const data = ''; // Explicitly set data to an empty string for GET request
      
          // Create HMAC
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          const hmac = CryptoJS.HmacSHA256(data, secretKey);
          const hmacDigest = hmac.toString(CryptoJS.enc.Hex);
      
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales`, {
            headers: {
              'x-access-token': localStorage.getItem('token'),
              'x-hmac-Signature': hmacDigest,
              'cache-control': 'no-cache',
            },
            withCredentials: true,
          });
      
          setSales(response.data);
          setSalesLoaded(true);
          setLoading(false);
        } catch (error) {
          console.error('Error:', error.message || 'Failed to connect to server');
        }
      }

      useEffect(() => {
        if (user._id) {
            setSales([]);  // Clear sales state before fetching
            populateHome();
        }
    }, [user._id]); 

    const sortedSales = sales
  .filter((sale) => sale.projectName === user.projectAdmin) // Filter sales by projectName
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt in descending order
  .slice(0, 10); // Take the first 10 items after sorting


    return(
        <div>
            <h1 className="py-3 font-bold text-2xl">Campaign Admin for {user.projectAdmin}</h1>
<table className='overflow-x-scroll divide-y w-full divide-blue-300 '>
                <thead className='bg-blue-50'>
                    <tr>
                    <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>                    <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>

                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                        <th className='px-2 py-3 text-center  text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">
                    {sortedSales.length > 0 ? (
                        sortedSales.map((sale) => (
                            <tr key={sale._id}>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                <td className='px-2 py-3 text-sm max-w-md  break-words text-gray-600'>{sale.outcome}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className='px-2 py-3 text-center text-sm text-gray-600'>No Data</td>
                        </tr>
                    )}
                </tbody>
                        </table>       
                        
                         </div>
    )
}