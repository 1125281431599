import React, { useState } from "react";
import axios from "axios";

export default function VerifyEmail({ verifyToggle }) {
  const [email, setEmail] = useState(''); // Initialize with an empty string

  const verifyEmail = async () => {
    if (!email) {
      alert("Please enter your email");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/verify-email`, { email }, { withCredentials: true });
      alert("Verification email sent!");
    } catch (err) {
      console.error(err);
      alert("Error sending verification email");
    }
  };

  return (
    <div className="container flex fixed top-0 h-full min-w-full bg-black bg-opacity-30 justify-center items-center">
      <div className="bg-white rounded-2xl p-5 text-center container flex flex-col w-max px-10 gap-3">
        <h1 className="helv-med text-lg">Please Verify Your Email Address:</h1>
        <input
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="text-center border-2 border-serbBlue py-2"
        />
        <div className="container flex justify-between">
          <button type="button" className="text-left text-red-500" onClick={verifyToggle}>Close</button>
          <button type="submit" className="text-left text-serbBlue text-lg helv-med" onClick={verifyEmail}>Submit</button>
        </div>
      </div>
    </div>
  );
}
