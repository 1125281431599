import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logo from '../images/SerbWordWhite.svg';
import CryptoJS from 'crypto-js';
import SupplierSearchForm from '../components/SupplierSearchForm';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx'; // Import the xlsx library
import Nav from '../components/Nav';
import SearchSuperForm from '../components/SearchSuperForm';
import SearchAdminForm from '../components/SearchAdminForm';
import SearchCampaign from '../components/SearchCampaign';

export default function SearchSale() {
   const [user, setUser] = useState([])
   const navigate = useNavigate()

   

    // Fetch user data by userId
    const fetchUserData = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`);
            const userData = response.data;
            setUser(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    



    // Use effect to fetch user data first
   useEffect(() => {
    const fetchData = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = decodeToken(token);
            if (!user) {
                localStorage.removeItem('token');
                navigate('/');
            } else {
                await fetchUserData(user._id); // Wait for user data
                
            }
        } else {
            navigate('/');
        }
    };

    fetchData();
}, [navigate]);




    return (
        <div className="container bg-gradient-to-br from-blue-950 to-blue-500 flex flex-col min-w-full min-h-screen justify-center items-center place-content-center">
            <Nav />
            <img src={Logo} alt='Serbius Logo' className="w-1/4 my-5" />
            {user.accountType === 2 ? (
                <SearchSuperForm />
            ):
            (
                user.accountType === 3 ? (
                    <SearchCampaign />

                ):
                (
                    <SearchAdminForm />

                )
            )
            }
            <div className="container  flex justify-start md:w-5/6   sm:w-11/12 my-5">
                <p className="text-white">© 2022-2024 Aydmar Holdings Ltd. V1.1.4</p>

            </div>
        </div>
    );
}
