import React, { useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';

export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [searchParams] = useSearchParams(); // To get email and token from URL
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const email = searchParams.get('email');
        const token = searchParams.get('token');
    
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match!');
            return;
        }
    
        // Debug log to see the values
        console.log({ email, token, newPassword });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/reset-password`, {
                email,
                token,
                newPassword,
            });
    
            setSuccess('Password has been reset successfully!');
            setTimeout(() => navigate('/'), 2000);
        } catch (error) {
            console.error(error);
            setError('Error resetting password. Please try again.');
        }
    };
    

    return (
        <div className="container bg-gradient-to-br from-blue-950 to-blue-500 flex flex-col min-w-full min-h-screen justify-center items-center ">
            <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
                <h2 className="text-xl font-bold mb-4">Reset Password</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}
                
                <input
                    type="password"
                    placeholder="New Password"
                    className="w-full p-2 mb-4 border rounded"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />

                <input
                    type="password"
                    placeholder="Confirm New Password"
                    className="w-full p-2 mb-4 border rounded"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <button type="submit" className="w-full p-2 bg-serbBlue text-white rounded">Reset Password</button>
            </form>
        </div>
    );
}
