import React, {useState, useEffect} from "react";
import axios from "axios";
import CryptoJS from 'crypto-js';
import { TailSpin } from 'react-loader-spinner';


export default function SuperAdminDash({user}) {
    const [sales, setSales] = useState([]);
    const [leads, setLeads] = useState([]);

    const [loading, setLoading] = useState(true);
    const [salesLoaded, setSalesLoaded] = useState(false);


    async function populateHome() {
        try {
          const data = ''; // Explicitly set data to an empty string for GET request
      
          // Create HMAC
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          const hmac = CryptoJS.HmacSHA256(data, secretKey);
          const hmacDigest = hmac.toString(CryptoJS.enc.Hex);
      
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales`, {
            headers: {
              'x-access-token': localStorage.getItem('token'),
              'x-hmac-Signature': hmacDigest,
              'cache-control': 'no-cache',
            },
            withCredentials: true,
          });
      
          setSales(response.data);
          setSalesLoaded(true);
          setLoading(false);
        } catch (error) {
          console.error('Error:', error.message || 'Failed to connect to server');
        }
      }

      async function populateLeads() {
        try {
          const data = ''; // Explicitly set data to an empty string for GET request
      
          // Create HMAC
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          const hmac = CryptoJS.HmacSHA256(data, secretKey);
          const hmacDigest = hmac.toString(CryptoJS.enc.Hex);
      
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/leads`, {
            headers: {
              'x-hmac-Signature': hmacDigest,
              'cache-control': 'no-cache',
            },
            withCredentials: true,
          });
      
          setLeads(response.data);
          setSalesLoaded(true);
          setLoading(false);
        } catch (error) {
          console.error('Error:', error.message || 'Failed to connect to server');
        }
      }

      useEffect(() => {
        if (user._id) {
            setLeads([]);  // Clear leads state before fetching
            setSales([]);  // Clear sales state before fetching
            populateHome();
            populateLeads();
        }
    }, [user._id]); 

    const nswLeads = leads.filter(lead => lead.state === 'NSW');
    const qldLeads = leads.filter(lead => lead.state === 'QLD');
    const saLeads = leads.filter(lead => lead.state === 'SA');
    const tasLeads = leads.filter(lead => lead.state === 'TAS');
    const vicLeads = leads.filter(lead => lead.state === 'VIC');
    const waLeads = leads.filter(lead => lead.state === 'WA');
    const actLeads = leads.filter(lead => lead.state === 'ACT');


    const sortedSales = sales
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10); // Take the first 10 items after sorting

    return(
        <div className='container flex min-w-full h-full flex-col justify-center items-center'>
            {loading && (
              <div className='container flex min-w-full justify-center'>
                <TailSpin
                  visible={true}
                  height="60"
                  width="60"
                  color="#15BCC8"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  strokeWidth={"5px"}
                />
              </div>
            )}

            {!loading && (
                <div>
                <h1 className='text-2xl font-sans font-bold pb-5'>Dashboard</h1>
                <div className='sm:overflow-x-scroll container flex flex-col gap-5'>
                <table className='overflow-x-scroll divide-y w-full divide-blue-300 '>
                <thead className='bg-blue-50'>
                    <tr>
                    <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Total Records</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>NSW Records</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>QLD Records</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>SA Records</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>TAS Records</th>
                        <th className='px-2 py-3 text-center  text-xs font-medium text-slate-700 uppercase tracking-wider'>VIC Records</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>WA Records</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>ACT Records</th>


                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">
                   {sales.length > 0 ? (
                        
                            <tr key={sales._id}>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'><b>{leads.length}</b></td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{nswLeads.length}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{qldLeads.length}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{saLeads.length}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{tasLeads.length}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{vicLeads.length}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{waLeads.length}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{actLeads.length}</td>

                                


                            </tr>
                        
                    ) : (
                        <tr>
                            <td colSpan="6" className='px-2 py-3 text-center text-sm text-gray-600'>No Data</td>
                        </tr>
                    )} 
                </tbody>
                        </table>

                        <table className='overflow-x-scroll divide-y w-full divide-blue-300 '>
                <thead className='bg-blue-50'>
                    <tr>
                    <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                        <th className='px-2 py-3 text-center  text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                        <th className='px-2 py-3 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">
                    {sortedSales.length > 0 ? (
                        sortedSales.map((sale) => (
                            <tr key={sale._id}>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                <td className='px-2 py-3 text-sm max-w-md  break-words text-gray-600'>{sale.outcome}</td>
                                <td className='px-2 py-3 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className='px-2 py-3 text-center text-sm text-gray-600'>No Data</td>
                        </tr>
                    )}
                </tbody>
                        </table>

                        </div>
                 </div>
            )}

        </div>
    )
}