import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logo from '../images/serbius_white.png';
import CryptoJS from 'crypto-js';
import SupplierSearchForm from '../components/SupplierSearchForm';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx'; // Import the xlsx library


export default function SearchCampaign(){
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSales, setFilteredSales] = useState([]);
    const [selectedSaleId, setSelectedSaleId] = useState('');
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [sales, setSales] = useState([]);
    const [searchBy, setSearchBy] = useState('supplierCustomerId');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showAllSales, setShowAllSales] = useState(false); // New state for showing all sales
    const navigate = useNavigate();
    const [user, setUser] = useState([]);    
    const [loading, setLoading] = useState(false); // New loading state
    const [showWeek, setShowWeek] = useState(false)
    const [showMonth, setShowMonth] = useState(false)
    const [selectedStates, setSelectedStates] = useState([]);


    // Fetch user data by userId
    const fetchUserData = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`);
            const userData = response.data;
            setUser(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };


    // Use effect to fetch user data first
   useEffect(() => {
    const fetchData = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = decodeToken(token);
            if (!user) {
                localStorage.removeItem('token');
                navigate('/');
            } else {
                await fetchUserData(user._id); // Wait for user data
                populateSearch(); // Load search data after user and sales are fetched
            }
        } else {
            navigate('/');
        }
    };

    fetchData();
}, [navigate]);

useEffect(() => {
    if (user._id && user.projectAdmin) {
        populateSearch();
    }
}, [user._id, user.projectAdmin]);


   

    // Populate the search data
    async function populateSearch() {
        try {
            const data = '';
    
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const hmac = CryptoJS.HmacSHA256(data, secretKey);
            const hmacDigest = hmac.toString(CryptoJS.enc.Hex);
    
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales`, {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                    'X-HMAC-Signature': hmacDigest,
                },
                withCredentials: true,
            });
    
            // Sort the sales by date in descending order
            const sortedSales = response.data.sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate));
            
            // Filter sales by user.projectAdmin
            const filteredByProject = sortedSales.filter(sale => sale.projectName === user.projectAdmin);
            setSales(filteredByProject);
        } catch (error) {
            console.error('Error:', error.message || 'Failed to connect to server');
        }
    }
    

    //Export to PDF
    const exportAgentToPDF = () => {
        const input = document.getElementById('agent-table');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('sales_report.pdf');
            })
            .catch((err) => console.error('Error generating PDF', err));
    };

    const exportAllToPDF = () => {
        const input = document.getElementById('all-table');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('sales_report.pdf');
            })
            .catch((err) => console.error('Error generating PDF', err));
    };

    const exportIdToPDF = () => {
        const input = document.getElementById('id-table');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('sales_report.pdf');
            })
            .catch((err) => console.error('Error generating PDF', err));
    };

    //export to Excel
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredSales.map(sale => ({
            SupplierCustomerId: sale.supplierCustomerId,
            Date: new Date(sale.submitDate).toLocaleDateString(),
            CustomerName: `${sale.firstName} ${sale.lastName}`,
            RaffleType: sale.raffleType ? 'VIP' : 'One Time Payment',
            Pledge: sale.pledge,
            Outcome: sale.outcome,
            Agent: sale.agentName
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');
        XLSX.writeFile(workbook, 'sales.xlsx');
    };

    const getLastWeekDateRange = () => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 7);
        return { start: lastWeek, end: today };
      };
      
      // Function to calculate the date range for the last month
      const getLastMonthDateRange = () => {
        const today = new Date();
        const lastMonth = new Date(today);
        lastMonth.setMonth(today.getMonth() - 1);
        return { start: lastMonth, end: today };
      };

      const handleShowWeek = () => {
        setShowWeek(!showWeek);
      
        if (!showWeek) {
          const { start, end } = getLastWeekDateRange();
          const filtered = filterSalesByDateRange2(sales, start, end);
          setFilteredSales(filtered);
        } else {
          setFilteredSales([]); // Clear sales when unchecked
        }
      };
      
      const handleShowMonth = () => {
        setShowMonth(!showMonth);
      
        if (!showMonth) {
          const { start, end } = getLastMonthDateRange();
          const filtered = filterSalesByDateRange2(sales, start, end);
          setFilteredSales(filtered);
        } else {
          setFilteredSales([]); // Clear sales when unchecked
        }
      };

      const filterSalesByDateRange2 = (salesToFilter, startDate, endDate) => {
    return salesToFilter
        .filter(sale => {
            const saleDate = new Date(sale.submitDate);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return saleDate >= start && saleDate <= end;
        })
        .sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate)); // Sort by date descending        
};


    // Extract unique agent names for filtering
    const agentNames = [...new Set(sales.map(sale => sale.agentName))];
    const filteredAgents = agentNames.filter(name =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAgentSelect = (agentName) => {
        const filtered = filterSalesByDateRange(sales.filter(sale =>
            sale.agentName && sale.agentName.toLowerCase() === agentName.toLowerCase()
        ));
        setSelectedAgent(agentName);
        setFilteredSales(filtered);
    };

    //Search

    const handleSearchChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    
        if (searchTerm === '') {
            setFilteredSales([]);
        } else {
            const filtered = sales
                .filter(sale => {
                    if (searchBy === 'supplierCustomerId') {
                        return sale.supplierCustomerId && sale.supplierCustomerId.toLowerCase() === searchTerm.toLowerCase();
                    } else if (searchBy === 'agentName') {
                        return sale.agentName && sale.agentName.toLowerCase().includes(searchTerm.toLowerCase());
                    } else if (searchBy === 'projectName') {
                        return sale.projectName && sale.projectName.toLowerCase().includes(searchTerm.toLowerCase()) 
                               && sale.projectName === user.projectAdmin; // Ensure it matches user.projectAdmin
                    }
                    return false;
                })
                .sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate)); // Sort by date descending
            setFilteredSales(filtered);
        }
    };
    
    

    
    

    const handleSearchTypeChange = (event) => {
        setSearchBy(event.target.value);
        setSearchTerm('');
        setFilteredSales([]);
    };

    const handleSaleSelect = (saleId) => {
        setSelectedSaleId(saleId);
    };

    //Date Change

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleShowAllSalesToggle = () => {
        setLoading(true); // Start loading
        setShowAllSales(!showAllSales);
        
        if (!showAllSales) {
            const filtered = filterSalesByDateRange(sales);
            setFilteredSales(filtered);
        } else {
            setFilteredSales([]); // Reset selectedSaleId to prevent SupplierSearchForm from showing
        }
        setLoading(false); // End loading


    };


    

    const filterSalesByDateRange = (salesToFilter) => {
        if (!startDate || !endDate) return salesToFilter;
        return salesToFilter
            .filter(sale => {
                const saleDate = new Date(sale.submitDate);
                const start = new Date(startDate);
                const end = new Date(endDate);
                return saleDate >= start && saleDate <= end;
            })
            .sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate)); // Sort by date descending        
    };


    // Updated handlePaidChange function
const handlePaidChange = async (saleId) => {
    try {
        // Find the sale to be updated
        const sale = sales.find(sale => sale._id === saleId);
        if (!sale) {
            console.error(`Sale with ID ${saleId} not found.`);
            return;
        }

        // Determine the new paid status
        const newPaidStatus = !sale.paid;

        // Update the sales state
        setSales(prevSales =>
            prevSales.map(s =>
                s._id === saleId ? { ...s, paid: newPaidStatus } : s
            )
        );

        // Update the filteredSales state
        setFilteredSales(prevFiltered =>
            prevFiltered.map(s =>
                s._id === saleId ? { ...s, paid: newPaidStatus } : s
            )
        );

        // Update the backend with the new paid status
        await updatePaidStatusInDB(saleId, newPaidStatus);
    } catch (error) {
        console.error('Error updating the paid status:', error);
        // Optionally, revert the UI change if the backend update fails
        setSales(prevSales =>
            prevSales.map(s =>
                s._id === saleId ? { ...s, paid: !s.paid } : s
            )
        );
        setFilteredSales(prevFiltered =>
            prevFiltered.map(s =>
                s._id === saleId ? { ...s, paid: !s.paid } : s
            )
        );
    }
};

// Updated updatePaidStatusInDB function
const updatePaidStatusInDB = async (saleId, newPaidStatus) => {
    try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/sales/${saleId}`, {
            paid: newPaidStatus,
        });
        console.log(`Sale ${saleId} updated successfully in the database.`);
    } catch (error) {
        console.error(`Error updating sale ${saleId} in the database:`, error);
        throw error; // Re-throw the error to handle it in the calling function
    }
};

    
    
      
       // Fetch sales when user data is available
    useEffect(() => {
        if (user._id) {
            populateSearch();
        }
    }, [user._id]);

    // Handle checkbox state selection
    const handleStateChange = (event) => {
        const state = event.target.value;
        const checked = event.target.checked;

        if (checked) {
            // Add the state to the selectedStates array
            setSelectedStates([...selectedStates, state]);
        } else {
            // Remove the state from the selectedStates array
            setSelectedStates(selectedStates.filter((selected) => selected !== state));
        }
    };

      

    return(
        <div className="container  flex flex-col justify-center bg-white p-3 m-3 rounded-2xl text-center md:min-w-min sm:w-11/12 shadow-lg shadow-blue-900">
                <div className='font-sans'>
                    
                    <form >
                        <div className='container flex justify-between w-full gap-5'>
                            <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="searchType">
                                Super Search:
                            </label>
                            <select
                                id="searchType"
                                className='border-solid h-12 text-center text-black bg-slate-300 p-1 md:w-1/4 sm:w-2/4 rounded-lg'
                                value={searchBy}
                                onChange={handleSearchTypeChange}
                            >
                                <option value="supplierCustomerId">Supplier Customer Id</option>
                                <option value="agentName">Agent Name</option>
                                <option value="projectName">Project Name</option> {/* New option */}
                            </select>
                        </div>
                        
                        <div className='container flex justify-between w-full gap-5 mt-2'>
                            <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="leadSearch">
                                Search Term:
                            </label>
                            <input
                                className='border-solid h-12 text-center text-black bg-slate-300 p-1 md:w-1/4 sm:w-2/4 rounded-lg'
                                id="leadSearch"
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search"
                            />
                        </div>

                        
                            <div className='container grid md:grid-cols-2 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2 gap-5 mb-3'>
                                <div className='container md:col-span-1 flex justify-between w-full gap-5 mt-2'>
                                    <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 ' htmlFor="startDate">
                                        Start Date:
                                    </label>
                                    <input
                                        className='border-solid h-12 text-center text-black bg-slate-300 p-1 w-1/2 rounded-lg'
                                        id="startDate"
                                        type="date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                    />
                                </div>

                                <div className='container flex justify-between w-full gap-5 mt-2'>
                                    <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="endDate">
                                        End Date:
                                    </label>
                                    <input
                                        className='border-solid h-12 text-center text-black bg-slate-300 p-1 w-1/2 rounded-lg'
                                        id="endDate"
                                        type="date"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                    />
                                </div>
                            </div>

                            {/* New Button to Show All Sales */}
                            <div className='container flex justify-between w-full gap-5  my-2'>
                            <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="showAllSales">
                                Show All Sales:
                            </label>
                            <input
                                className='border-solid  text-right bg-blue-300 text-black w-10  rounded-lg'
                                id="showAllSales"
                                type="checkbox"
                                checked={showAllSales}
                                onChange={handleShowAllSalesToggle} // Toggle state for showing all sales
                            />
                            </div>

                         {/* Filtered Sales Table */}
                         {showAllSales && filteredSales.length > 0 && (
                            <div className='sm:overflow-x-scroll overflow-x-scroll min-w-full'>
                            <table id='all-table' className='min-w-full overflow-x-scroll  divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent</th>                                        
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                {filteredSales.map((sale) => (
                                    <tr key={sale._id}>
                                        <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                        <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                        <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                        <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                        <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                        <td className='px-2 py-2 max-w-md break-words text-sm text-gray-600'>{sale.outcome}</td>
                                        <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                        {/* Remove the second map and directly add the checkbox here */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                id="paid"
                                                name="paid"
                                                checked={sale.paid || false}
                                                onChange={() => handlePaidChange(sale._id)}  // Update the status when clicked
                                            />
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                            </div>
                        )}
                        
                        {!showAllSales && searchBy === 'supplierCustomerId' && filteredSales.length > 0 && !showWeek && !showMonth && (
                            
                            <div id='id-table' className='max-w-full'>
                                <ul  className='bg-white container flex gap-2 flex-wrap  border-serbBlue p-1 border-2 rounded-lg my-2'>
                                    {filteredSales.map(lead => (
                                        <li
                                            key={lead._id}
                                            className='cursor-pointer w-max p-2 hover:bg-blue-200 rounded-lg'
                                            onClick={() => handleSaleSelect(lead._id)}
                                        >
                                            {lead.supplierCustomerId}
                                        </li>
                                    ))}
                                </ul>

                                {!showAllSales && selectedSaleId && searchBy === 'supplierCustomerId' && (
                                    <SupplierSearchForm
                                        selectedSale={sales.find(sale => sale._id === selectedSaleId)}
                                    />
                                )}
                            </div>
                        )}

                        

                        

                        {!showAllSales && searchBy === 'agentName' && searchTerm && (
                            <div className='max-w-full'>
                                <ul className='bg-white container flex flex-wrap gap-2  border-blue-800 p-1 border-2 rounded-lg my-2'>
                                    {filteredAgents.map(agentName => (
                                        <li
                                            key={agentName}
                                            className='cursor-pointer  w-max p-2 hover:bg-blue-200 rounded-lg'
                                            onClick={() => handleAgentSelect(agentName)}
                                        >
                                            {agentName}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!showAllSales && selectedAgent && searchBy === 'agentName' && filteredSales.length > 0 && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSales.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>
                                                <input
                                                    type="checkbox"
                                                    id="paid"
                                                    name="paid"
                                                    checked={sale.paid || false}  // Check if paid is true
                                                    onChange={() => handlePaidChange(sale._id)}  // Update the status when clicked
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {!showAllSales  && showWeek && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSales.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>
                                                <input
                                                    type="checkbox"
                                                    id="paid"
                                                    name="paid"
                                                    checked={sale.paid || false}  // Check if paid is true
                                                    onChange={() => handlePaidChange(sale._id)}  // Update the status when clicked
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {!showAllSales && !showWeek && !showMonth   && searchBy === 'projectName' && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Campaign</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSales.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.projectName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>
                                                <input
                                                    type="checkbox"
                                                    id="paid"
                                                    name="paid"
                                                    checked={sale.paid || false}  // Check if paid is true
                                                    onChange={() => handlePaidChange(sale._id)}  // Update the status when clicked
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {!showAllSales  && showMonth  && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSales.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>
                                                <input
                                                    type="checkbox"
                                                    id="paid"
                                                    name="paid"
                                                    checked={sale.paid || false}  // Check if paid is true
                                                    onChange={() => handlePaidChange(sale._id)}  // Update the status when clicked
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {filteredSales.length === 0 && searchTerm && <div className='mt-2 text-red-500'>No results found</div>}
                    </form>
                </div>
                <div className='container flex md:flex-row sm:flex-col justify-between w-full text-center gap-4'>
                    <div className='container flex w-80 flex-col justify-center items-center gap-1'>
                        <a
                            className='text-center hover:cursor-pointer container flex items-center justify-center font-reg py-1 text-xl text-black h-16 rounded-2xl bg-gradient-to-br from-slate-300 to-slate-400 hover:bg-gradient-to-lr hover:from-slate-400 hover:text-slate-900 hover:font-medium hover:to-slate-200 hover:shadow-lg hover:shadow-slate-700'
                            type="button"
                            href='/home'
                        >
                            Home
                        </a>
                        
                    </div>

                    <div className='container flex helv-med items-center'>
                        <div className='container flex flex-col'>
                            <h1>Show Last Weeks Sales</h1>
                            <input
                            type='checkbox'
                            checked={showWeek}
                            onChange={() => handleShowWeek()}
                            />
                        </div>

                        <div className='container flex flex-col w-max'>
                            <h1>Show Last Months Sales</h1>
                            <input
                            type='checkbox'
                            checked={showMonth}
                            onChange={() => handleShowMonth()}

                            />
                        </div>
                    </div>

                    <div className='w-1/4'>
                    

                    <button 
                        onClick={exportToExcel} 
                        className="text-white m-1 mt-2 py-2 px-2 bg-green-600 rounded-lg w-max"
                    >
                        Export to Excel
                    </button>
            </div>
                </div>
            </div>
    )
}