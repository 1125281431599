import logo from './logo.svg';
import './css/App.css';
import {  Router, Routes, Route } from 'react-router-dom';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import SearchSale from './pages/SearchSales';
import EmailConfirmation from './pages/EmailConfirm';
import Profile from './pages/Profile';
//import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Upload from './pages/Upload';
import SearchLeads from './pages/SearchLeads';


function App() {
  return (
        <Routes>
        <Route exact path='/' Component={SignIn} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route exact path='/home' Component={Home} />
        <Route exact path='/upload' Component={Upload} />
        <Route exact path='/search-sale' Component={SearchSale} />
        <Route exact path='/email-confirm' Component={EmailConfirmation} />
        <Route exact path='/profile' Component={Profile} />
        <Route exact path='/search-lead' Component={SearchLeads} />
        </Routes>

  );
}

export default App;
