import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Logo from '../images/SerbWordWhite.svg';
import Dashboard from '../components/Dashboard';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

import Nav from '../components/Nav';

export default function Home() {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salesLoaded, setSalesLoaded] = useState(false);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    } else {
      navigate('/'); // Redirect to sign-in if user info is not found
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = decodeToken(token);
      if (!decodedToken) {
        localStorage.removeItem('token');
        navigate('/'); // Redirect to login if token is invalid
      } else {
        populateHome(); // Only populate home if token is valid
      }
    } else {
      navigate('/'); // Redirect if no token is found
    }
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`);
        const userData = response.data;
        setUser(userData);

        // Fetch each member's details
        
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

// Use in your useEffect
useEffect(() => {
  if (user && user._id) {
    // Only fetch data if the id has changed
    if (userIdRef.current !== user._id) {
      fetchUserData(user._id);
      userIdRef.current = user._id; // Update the ref to the current user._id
    }
  }
}, [user]);

const userIdRef = useRef(user ? user._id : null);



// Use in your useEffect


  async function populateHome() {
    try {
      const data = ''; // Explicitly set data to an empty string for GET request

      // Create HMAC
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(data, secretKey);
      const hmacDigest = hmac.toString(CryptoJS.enc.Hex);

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales`, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'x-hmac-signature': hmacDigest,
          
        },
        withCredentials: true,
      });

      setSales(response.data);
      setSalesLoaded(true);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error.message || 'Failed to connect to server');
    }
  }



  return (
    <div className="container bg-gradient-to-br from-blue-950 to-blue-500 flex flex-col min-w-full min-h-screen justify-center items-center ">
      <div className="my-0"><Nav user={user}/></div>
      <img src={Logo} alt='Serbius Logo' className="w-1/4 my-5" />
      
      
      
        <div>
          
           
           <div className='container flex min-w-full h-full flex-col justify-center items-center'>
           <div className="container flex flex-col justify-center bg-white p-5 rounded-2xl text-center md:min-w-max sm:w-11/12 shadow-lg shadow-blue-900">
             {loading && (
               <div className='container flex min-w-full justify-center'>
                 <TailSpin
                   visible={true}
                   height="60"
                   width="60"
                   color="#15BCC8"
                   ariaLabel="tail-spin-loading"
                   radius="1"
                   strokeWidth={"5px"}
                 />
               </div>
             )}

             {user.accountType === 0 && (
              <p className='w-full'>You don't have access to this site </p>
            )}
     
             
     <div>
                 <div className='sm:overflow-x-scroll'>
                   <Dashboard sales={sales} user={user} setLoading={setLoading} TailSpin={TailSpin} populateHome={populateHome}/>
                 </div>
                 <div className='container flex w-full justify-end'>
                   <a type='button'
                     className='text-center w-80 mt-3 hover:cursor-pointer container flex items-center justify-center font-bold py-1 text-xl text-white h-16 rounded-2xl bg-gradient-to-br  from-blue-800 to-blue-500 hover:bg-gradient-to-lr hover:from-blue-600 hover:to-blue-800 hover:shadow-lg hover:shadow-slate-700'
                     href='/search-sale'
                   >Search Sales</a>
                 </div>
               </div>
           </div>
     
           
           </div>
           
         
        
           <div className="container flex justify-start md:w-5/6 sm:w-11/12 my-5">
             <p className="text-white text-sm">Copyright © 2024 Aydmar Holdings Ltd. V1.3</p>
           </div>
          </div>
      
    </div>
  );
}
