import React, {useState, useEffect} from "react";
import axios from "axios";


export default function AddAgentAdminForm({setAgentAdminForm, adminId}) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    username: '',
    team: adminId.team
  });
  const [error, setError] = useState('');

  
  // Fetch admin data to get the team name when the component is mounted
  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${adminId}`);
        const adminData = response.data;
        // Set the team name for the agent
        setFormData((prevData) => ({ ...prevData, team: adminData.team }));
      } catch (err) {
        console.error('Error fetching admin data:', err);
        setError('Failed to load admin data');
      }
    };
    fetchAdminData();
  }, [adminId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const agentData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      team: formData.team,  // The team name should now be correctly set
      submissions: [],
      accountType: 0,
      username: formData.username,
    };

    try {
      // Post the new agent to the user's database
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/add`, agentData);
      console.log(agentData);
      // Update the admin's members array
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${adminId}/add-agent`, { agent: agentData });

      setAgentAdminForm(false); // Close the form on success
      alert("Successfully added Agent");
    } catch (err) {
      setError('Failed to add agent. Please try again.');
      alert(error);
    }
  };

  
    return(
      <div className="container flex admin-agent  justify-center items-center bg-black bg-opacity-50 min-w-full min-h-full  overflow-y-scroll  fixed">
        <form onSubmit={handleSubmit} className='mt-4 p-4 border w-3/4 max-h-3/4  bg-white rounded'>
          <h1 className="py-2 text-xl font-sans font-bold">Add Agent </h1>
          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700'>First Name</label>
            <input
              type='text'
              name='firstName'
              value={formData.firstName || ''}
              onChange={handleInputChange}
              className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50'
              required
            />
          </div>

          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700'>Last Name</label>
            <input
              type='text'
              name='lastName'
              value={formData.lastName || ''}
              onChange={handleInputChange}
              className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50'
              required
            />
          </div>

          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700'>Username</label>
            <input
              type='text'
              name='username'
              value={formData.username || ''}
              onChange={handleInputChange}
              className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50'
              required
            />
          </div>

          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700'>Password</label>
            <input
              type='text'
              name='password'
              value={formData.password || ''}
              onChange={handleInputChange}
              className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50'
              required
            />
          </div>

          <div className='flex justify-between'>
          <button type='button' onClick={() => setAgentAdminForm(false)} className='ml-2 px-4 py-2 bg-gray-300 text-black rounded-md'>
              Cancel
            </button>
            <button type='submit' className='px-4 py-2 bg-purple-500 text-white rounded-md'>
              Add Agent
            </button>
            
          </div>
        </form>
        </div>
    )
}