import React, {useEffect} from 'react';

export default function AddAdminForm({ handleAddAdminSubmit, handleInputChange, handleMemberChange, removeMemberField, newAdmin, setNewAdmin , addMemberField, setShowAddAdmin}) {
  
  
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center admin min-h-screen  overflow-y min-w-full'>
      <div className='bg-white p-6 rounded-lg mt-10  w-3/4 max-h-screen overflow-y-scroll'>
        <h2 className='text-xl font-bold mb-4'>Add New Admin</h2>
        <form className='overflow-y-scroll' onSubmit={handleAddAdminSubmit}>
          <div className='container grid md:grid-cols-2 sm:grid-cols-1 gap-5'>
            
              <div>
                <label className='block text-sm font-bold'>First Name</label>
                <input 
                  type='text' 
                  name='firstName' 
                  value={newAdmin.firstName} 
                  onChange={handleInputChange} 
                  className='w-full border px-3 py-2' 
                  required 
                />
              </div>
              
              <div>
                <label className='block text-sm font-bold'>Last Name</label>
                <input 
                  type='text' 
                  name='lastName' 
                  value={newAdmin.lastName} 
                  onChange={handleInputChange} 
                  className='w-full border px-3 py-2' 
                  required 
                />
              </div>
            
            
              <div>
                <label className='block text-sm font-bold'>Email</label>
                <input 
                  type='email' 
                  name='email' 
                  value={newAdmin.email} 
                  onChange={handleInputChange} 
                  className='w-full border px-3 py-2' 
                  required 
                />
              </div>
              <div>
                <label className='block text-sm font-bold'>Password</label>
                <input 
                  type='text' 
                  name='password' 
                  value={newAdmin.password} 
                  onChange={handleInputChange} 
                  className='w-full border px-3 py-2' 
                  required 
                />
              </div>
            
            <div className='mb-4 '>
              <label className='block text-sm font-bold'>Team</label>
              <input 
                type='text' 
                name='team' 
                value={newAdmin.team} 
                onChange={handleInputChange} 
                className='w-full border px-3 py-2' 
                required 
              />
            </div>
            <div className='mb-4'>
              <div className='container grid grid-cols-2'>
                <div>
                <label className='block text-sm font-bold'>Campaign Admin?</label>
                <input
                    type="checkbox"
                    checked={newAdmin.campAdmin}
                    onChange={(e) =>
                      setNewAdmin((prev) => ({
                        ...prev,
                        campAdmin: e.target.checked,
                      }))
                    }
                  />
                </div>

                {newAdmin.campAdmin === true && (
                  <div>
                  <label className='block text-sm font-bold'>Campaign Name</label>
                  <input 
                    type='text' 
                    name='projectAdmin' 
                    value={newAdmin.projectAdmin} 
                    onChange={handleInputChange} 
                    className='w-full border px-3 py-2' 
                    required 
                  />
                </div>
                )}
              
              </div>
            </div>
          </div>
          <div className='mb-4 '>
            <label className='block text-md  font-bold'>Agents (Members)</label>
            <div className=' container flex gap-5 flex-col  divide-y-2 divide-solid '>
            {newAdmin.members.map((member, index) => (
              <div key={index} className='pt-5'>
                <div className='flex flex-col'>
                    <div className='container flex gap-5'>
                  <input 
                    type='text' 
                    name='firstName' 
                    value={member.firstName} 
                    onChange={(e) => handleMemberChange(index, e)} 
                    placeholder='First Name' 
                    className='w-full border px-3 py-2 mb-2' 
                    required 
                  />
                  <input 
                    type='text' 
                    name='lastName' 
                    value={member.lastName} 
                    onChange={(e) => handleMemberChange(index, e)} 
                    placeholder='Last Name' 
                    className='w-full border px-3 py-2 mb-2' 
                    required 
                  />
                  </div>
                  <input 
                    type='text' 
                    name='username' 
                    value={member.username} 
                    onChange={(e) => handleMemberChange(index, e)} 
                    placeholder='Username' 
                    className='w-full border px-3 py-2 mb-2' 
                    required 
                  />
                  <input 
                    type='text' 
                    name='password' 
                    value={member.password} 
                    onChange={(e) => handleMemberChange(index, e)} 
                    placeholder='Password' 
                    className='w-full border px-3 py-2' 
                    required 
                  />
                </div>
                <button
              type='button'
              onClick={() => removeMemberField(index)}
              className='bg-red-300 text-white mt-3 px-4 py-2 float-end rounded'>
              Remove Agent
            </button>
                
                
              </div>
              
            ))}
            </div>
            <button
              type='button'
              onClick={addMemberField}
              className='bg-pink-300 text-white px-4 py-2 rounded'>
              Add Agent
            </button>
          </div>
          <div className='flex justify-between'>
          <button type='button' className='bg-gray-500  text-white px-4 py-2 rounded-lg' onClick={() => setShowAddAdmin(false)}>Cancel</button>

            <button type='submit' className='bg-purple-500 w-1/4 text-white px-4 py-2 rounded-lg'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
