import React, { useState } from 'react';

const SupplierSearchForm = ({ selectedSale }) => {
  const [localSale, setLocalSale] = useState(selectedSale || {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalSale({
      ...localSale,
      [name]: value,
    });
  };

  

  return (
    <div className='container flex flex-col gap-5 my-5'>
      <form >
      <div className='container flex flex-col gap-5 mt-5'>
            <div className='container grid lg:grid-cols-5 sm:grid-cols-5 w-full text-center justify-center gap-4'>
              <div className='container lg:col-span-1 sm:col-span-1  flex w-full flex-col justify-center'>
                <label className='text-md font-bold'>Title: </label>
                <select
                  className='text-center py-1 bg-slate-200'
                  name="title"
                  value={selectedSale?.title || ''}
                >
                  <option value="">--Title--</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Mr">Mr</option>
                </select>
              </div>
              <div className='container lg:col-span-2 sm:col-span-2  flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>First Name: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="firstName"
                  value={selectedSale?.firstName || ''}
                />
              </div>
              <div className='container lg:col-span-2 sm:col-span-2  flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Last Name: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="lastName"
                  value={selectedSale?.lastName || ''}
                />
              </div>
            </div>
  
            <div className='container grid lg:grid-cols-6 sm:grid-cols-3 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
              <div className='ontainer flex lg:col-span-3 sm:col-span-3 w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Address: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="address"
                  value={selectedSale?.address || ''}
                />
              </div>
  
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Suburb: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="suburb"
                  value={selectedSale?.suburb || ''}
                />
              </div>
  
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>State: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="state"
                  value={selectedSale?.state || ''}
                />
              </div>
  
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Postcode: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="postCode"
                  value={selectedSale?.postCode || ''}
                />
              </div>
            </div>
  
            <div className='container grid lg:grid-cols-3 sm:grid-cols-2 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
              <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
              <label className='text-md font-bold'>Email: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="email"
                  value={selectedSale?.email || ''}
                />
              </div>
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Phone Number: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="phoneNumber"
                  value={selectedSale?.phoneNumber || ''}
                />
              </div>
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center'>
              <label className='text-md font-bold'>Raffle Type: </label>
              <select
            className='text-center py-1 bg-slate-200'
            name="raffleType"
            value={selectedSale?.raffleType || ''}
            >

            <option value={1}>VIP</option>
            <option value={0}>One Time Payment</option>

            </select>
            </div>
            </div>
  
            <div className='ccontainer grid md:grid-cols-3 md:grid-rows-1 sm:grid-rows-3 sm:grid-cols-2 w-full text-center justify-center gap-4'>
            <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
              <label className='text-md font-bold'>Price (AUD): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="number"
                name="pledge"
                value={selectedSale?.pledge || ''}
              />
            </div>
              
              <div className='container lg:col-span-1 sm:col-span-2 flex w-full flex-col justify-center gap-1'>
              <label className='text-md font-bold'>Tickets (Quantity): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="number"
                name="ticket"
                value={selectedSale?.ticket || ''}
              />
            </div>
              <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Payment Type: </label>
                <select
  className='text-center py-1 bg-slate-200'
  name="paymentMethod"
  value={selectedSale?.paymentMethod || ''}
>
  <option value={0}>Card</option>
  <option value={1}>Bank</option>
</select>
              </div>
            </div>
  
            <div>
           {selectedSale?.paymentMethod === 1 ? (
              <div className='container grid lg:grid-cols-3 sm:grid-cols-2 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
                <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
                  <label className='text-md font-bold'>Account Name: </label>
                  <input
                    className='text-center py-1 bg-slate-200'
                    type="text"
                    name="accountName"
                    defaultValue=""
                    value={selectedSale?.accountName || ''}
                  />
                </div>
                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                  <label className='text-md font-bold'>BSB </label>
                  <input
                    className='text-center py-1 bg-slate-200'
                    type="text"
                    name="bsb"
                    defaultValue=""

                    value={selectedSale?.bsb || ''}
                  />
                </div>
                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center'>
                  <label className='text-md font-bold'>Account Number: </label>
                  <input
                    className='text-center py-1 bg-slate-200'
                    type="text"
                    name="accountNumber"
                    defaultValue=""

                    value={selectedSale?.accountNumber || ''}
                  />
                </div>
              </div>
            ) : (
              null
            )}
           </div>
  
            <div className='container grid md:grid-cols-3 md:grid-rows-1 sm:grid-cols-2 sm:grid-rows-2 w-full text-center justify-center gap-4'>
  
            <div className='container md:col-span-1 sm:col-span-2 flex w-full flex-col justify-center gap-1'>
            <label className='text-md font-bold'>Project Name: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="projectName"
                  value={selectedSale?.projectName || ''}
                />
              </div>
  
              <div className='container md:col-span-1 sm:col-span-2  flex w-full flex-col justify-center gap-1'>
              <label className='text-md font-bold'>Submit Date and Time: </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="text"
                name="submitDate"
                value={selectedSale?.submitDate || ''}
              />
                
              </div>

              <div className='container md:col-span-1 sm:col-span-2 flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Agent Name: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="agentName"
                  value={selectedSale?.agentName || ''}
                />
              </div>

              
            </div>

            <div className='container grid md:grid-cols-1 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
            <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md font-bold'>Outcome: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="outcome"
                  value={selectedSale?.outcome || ''}
                />
              </div>

              
            </div>

          </div>
  
        {/* Remaining fields follow the same pattern */}
  
        
      </form>
    </div>
  );
};

export default SupplierSearchForm;
