import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';

export default function Nav() {
  const navigate = useNavigate();
  const [user, setUser] = useState({}); // Initialize as an object
  const userIdRef = useRef(null);

  // Fetch user data
  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Load user from localStorage
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      userIdRef.current = parsedUser._id;
      fetchUserData(parsedUser._id); // Fetch user data after loading from localStorage
    }
  }, []); // Run only once

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  };

  return (
    <Menu as="div" className="absolute top-0 right-0 md:m-10 sm:mt-3 inline-block text-left">
      <div>
        <MenuButton className="inline-flex container items-center w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10 text-white">
            <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
          </svg>
          <ChevronDownIcon aria-hidden="true" className="-mr-1 h-6 w-6 text-gray-400" />
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
      >
        <div className="py-1 divide-y">
          <h1 className="px-4 py-2 bg-blue-100 font-sans font-reg text-md">{user.team}</h1>
          <MenuItem>
            <a href="/home" className="block px-4 py-2 text-sm text-gray-700">
              Home
            </a>
          </MenuItem>
        </div>
        <div className="py-1">
          <MenuItem>
            <a href="/profile" className="block px-4 py-2 text-sm text-gray-700">
              Profile
            </a>
          </MenuItem>
        </div>
        {user.accountType === 2 && (<div className="py-1">
          <MenuItem>
            <a href="/search-lead" className="block px-4 py-2 text-sm text-gray-700">
              Search Records
            </a>
          </MenuItem>
        </div>)}
        {user.accountType === 2 && (<div className="py-1">
          <MenuItem>
            <a href="/upload" className="block px-4 py-2 text-sm text-gray-700">
              Upload CSV
            </a>
          </MenuItem>
        </div>)}

        
        
        <div className="py-1">
          <MenuItem>
            <button className="block px-4 py-2 w-full text-left text-sm text-gray-700" onClick={logout}>
              Logout
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}
