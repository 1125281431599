import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logo from '../images/serbius_white.png';
import CryptoJS from 'crypto-js';
import SupplierSearchForm from '../components/SupplierSearchForm';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx'; // Import the xlsx library

export default function SearchAdminForm(){
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSubsubmissions, setFilteredSubsubmissions] = useState([]);
    const [selectedSaleId, setSelectedSaleId] = useState('');
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [searchBy, setSearchBy] = useState('supplierCustomerId');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showAllsubmissions, setShowAllsubmissions] = useState(false); // New state for showing all submissions
    const navigate = useNavigate();
    const [submissions, setSubmissions] = useState([]);
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(false); // New loading state
    const [showWeek, setShowWeek] = useState(false)
    const [showMonth, setShowMonth] = useState(false)

    const getLastWeekDateRange = () => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 7);
        return { start: lastWeek, end: today };
      };
      
      // Function to calculate the date range for the last month
      const getLastMonthDateRange = () => {
        const today = new Date();
        const lastMonth = new Date(today);
        lastMonth.setMonth(today.getMonth() - 1);
        return { start: lastMonth, end: today };
      };

      const handleShowWeek = () => {
        setShowWeek(!showWeek);
      
        if (!showWeek) {
          const { start, end } = getLastWeekDateRange();
          const filtered = filtersubmissionsByDateRange2(submissions, start, end);
          setFilteredSubsubmissions(filtered);
        } else {
          setFilteredSubsubmissions([]); // Clear submissions when unchecked
        }
      };
      
      const handleShowMonth = () => {
        setShowMonth(!showMonth);
      
        if (!showMonth) {
          const { start, end } = getLastMonthDateRange();
          const filtered = filtersubmissionsByDateRange2(submissions, start, end);
          setFilteredSubsubmissions(filtered);
        } else {
          setFilteredSubsubmissions([]); // Clear submissions when unchecked
        }
      };

      const filtersubmissionsByDateRange2 = (submissionsToFilter, startDate, endDate) => {
    return submissionsToFilter
        .filter(sale => {
            const saleDate = new Date(sale.submitDate);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return saleDate >= start && saleDate <= end;
        })
        .sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate)); // Sort by date descending        
};

    async function adminPopulateSearch(userId) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales/team`, {
                params: { userId }
            });


            const sortedAdminsubmissions = response.data.sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate));
            setSubmissions(sortedAdminsubmissions);

        } catch (error) {
            console.error('Error:', error.message || 'Failed to connect to server');
        }
    }

    // Fetch user data by userId
    const fetchUserData = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`);
            const userData = response.data;
            setUser(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };


    // Use effect to fetch user data first
   useEffect(() => {
    const fetchData = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = decodeToken(token);
            if (!user) {
                localStorage.removeItem('token');
                navigate('/');
            } else {
                await fetchUserData(user._id); // Wait for user data
                await adminPopulateSearch(user._id); // Then fetch submissions data
            }
        } else {
            navigate('/');
        }
    };

    fetchData();
}, [navigate]);


    // Fetch submissions when user data is available
    useEffect(() => {
        if (user._id) {
            adminPopulateSearch(user._id);
        }
    }, [user._id, submissions]);
    


    //Export to PDF
    const exportAgentToPDF = () => {
        const input = document.getElementById('agent-table');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('sales_report.pdf');
            })
            .catch((err) => console.error('Error generating PDF', err));
    };

    
    

    const exportAllToPDF = () => {
        const input = document.getElementById('all-table');
        if (!input) {
            console.error('Element not found for export');
            return;
        }
    
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('sales_report.pdf');
            })
            .catch((err) => console.error('Error generating PDF', err));
    };
    

    const exportIdToPDF = () => {
        const input = document.getElementById('id-table');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('sales_report.pdf');
            })
            .catch((err) => console.error('Error generating PDF', err));
    };

    //export to Excel
    const exportToExcel = () => {
        if (filteredSubsubmissions.length === 0) {
            console.error('No data to export');
            return;
        }
    
        const worksheet = XLSX.utils.json_to_sheet(filteredSubsubmissions.map(sale => ({
            SupplierCustomerId: sale.supplierCustomerId,
            Date: new Date(sale.submitDate).toLocaleDateString(),
            CustomerName: `${sale.firstName} ${sale.lastName}`,
            RaffleType: sale.raffleType ? 'VIP' : 'One Time Payment',
            Pledge: sale.pledge,
            Outcome: sale.outcome,
            Agent: sale.agentName
        })));
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');
        XLSX.writeFile(workbook, 'sales.xlsx');
    };
    


    // Extract unique agent names for filtering

    const agentSubNames = [...new Set(submissions.map(submission => submission.agentName))];
    const filteredSubAgents = agentSubNames.filter(name =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAgentSubSelect = (agentName) => {
        const filtered = filtersubmissionsByDateRange(submissions.filter(submission =>
            submission.agentName && submission.agentName.toLowerCase() === agentName.toLowerCase()
        ));
        setSelectedAgent(agentName);
        setFilteredSubsubmissions(filtered);
    };

    const filtersubmissionsByDateRange = (submissionsToFilter) => {
        if (!startDate || !endDate) return submissionsToFilter;
        return submissionsToFilter
            .filter(sale => {
                const saleDate = new Date(sale.submitDate);
                const start = new Date(startDate);
                const end = new Date(endDate);
                return saleDate >= start && saleDate <= end;
            })
            .sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate)); // Sort by date descending        
    };

    //Search

    const handleSubSearchChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    
        if (searchTerm === '') {
            setFilteredSubsubmissions([]);
        } else {
            const filtered = filtersubmissionsByDateRange(submissions.filter(submission => {
                if (searchBy === 'supplierCustomerId') {
                    // Use strict equality to ensure exact match
                    return submission.supplierCustomerId && submission.supplierCustomerId.toLowerCase() === searchTerm.toLowerCase();
                } else if (searchBy === 'agentName') {
                    return submission.agentName && submission.agentName.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            }));
            setFilteredSubsubmissions(filtered.sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate)));
        }
    };
    

    

    const handleSearchTypeChange = (event) => {
        setSearchBy(event.target.value);
        setSearchTerm('');
        setFilteredSubsubmissions([]);
    };

    const handleSubmissionSelect = (submissionId) => {
        setSelectedSaleId(submissionId);
    };

    //Date Change

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleShowAllSubmissionsToggle = () => {
        setLoading(true); // Start loading
        setShowAllsubmissions(!showAllsubmissions);

        if (!showAllsubmissions) { // When showing all submissions
            const filtered = filterSubmissionsByDateRange(submissions);
            setFilteredSubsubmissions(filtered);
        } else {
            setFilteredSubsubmissions([]); // Clear immediately
        }
        setLoading(false); // End loading
    };
    

    const filterSubmissionsByDateRange = (submissionsToFilter) => {
        if (!startDate && !endDate) return submissionsToFilter;  // No date filters applied
    
        return submissionsToFilter.filter(submission => {
            const submissionDate = new Date(submission.submitDate);
            let isWithinRange = true;
    
            // Convert the string date inputs to Date objects
            if (startDate) {
                const start = new Date(startDate);  // Start date as Date object
                isWithinRange = isWithinRange && submissionDate >= start;
            }
            if (endDate) {
                const end = new Date(endDate);  // End date as Date object
                end.setHours(23, 59, 59, 999);  // Set end date to end of day
                isWithinRange = isWithinRange && submissionDate <= end;
            }
    
            return isWithinRange;
        }).sort((a, b) => new Date(b.submitDate) - new Date(a.submitDate));  // Sort by date descending
    };
    


    return (
        <div className="container  flex flex-col justify-center bg-white p-3 m-3 rounded-2xl text-center md:min-w-min sm:w-11/12 shadow-lg shadow-blue-900">
                <div className='font-sans'>
                    <form >
                        <div className='container flex justify-between w-full gap-5'>
                            <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="searchType">
                                Search by:
                            </label>
                            <select
                                id="searchType"
                                className='border-solid h-12 text-center text-black bg-slate-300 p-1 md:w-1/4 sm:w-2/4 rounded-lg'
                                value={searchBy}
                                onChange={handleSearchTypeChange}
                            >
                                <option value="supplierCustomerId">Supplier Customer Id</option>
                                <option value="agentName">Agent Name</option>
                            </select>
                        </div>
                        
                        <div className='container flex justify-between w-full gap-5 mt-2'>
                            <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="leadSearch">
                                Search Term:
                            </label>
                            <input
                                className='border-solid h-12 text-center text-black bg-slate-300 p-1 md:w-1/4 sm:w-2/4 rounded-lg'
                                id="leadSearch"
                                type="text"
                                value={searchTerm}
                                onChange={handleSubSearchChange}
                                placeholder="Search"
                            />
                        </div>

                        
                            <div className='container grid md:grid-cols-2 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2 gap-5 mb-3'>
                                <div className='container md:col-span-1 flex justify-between w-full gap-5 mt-2'>
                                    <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 ' htmlFor="startDate">
                                        Start Date:
                                    </label>
                                    <input
                                        className='border-solid h-12 text-center text-black bg-slate-300 p-1 w-1/2 rounded-lg'
                                        id="startDate"
                                        type="date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                    />
                                </div>

                                <div className='container flex justify-between w-full gap-5 mt-2'>
                                    <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="endDate">
                                        End Date:
                                    </label>
                                    <input
                                        className='border-solid h-12 text-center text-black bg-slate-300 p-1 w-1/2 rounded-lg'
                                        id="endDate"
                                        type="date"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                    />
                                </div>
                            </div>

                            {/* New Button to Show All submissions */}
                            <div className='container flex justify-between w-full gap-5  my-2'>
                            <label className='md:text-xl sm:text-md font-sans text-left font-black p-1 w-2/4' htmlFor="showAllsubmissions">
                                Show All submissions:
                            </label>
                            <input
                                className='border-solid  text-right bg-blue-300 text-black w-10  rounded-lg'
                                id="showAllsubmissions"
                                type="checkbox"
                                checked={showAllsubmissions}
                                onChange={handleShowAllSubmissionsToggle} // Toggle state for showing all submissions
                            />
                            </div>

                         {/* Filtered submissions Table */}
                         {showAllsubmissions && filteredSubsubmissions.length > 0 && (
                            <div className='sm:overflow-x-scroll overflow-x-scroll min-w-full'>
                            <table id='all-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSubsubmissions.map((submission) => (
                                        <tr key={submission._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{submission.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(submission.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{submission.firstName} {submission.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{submission.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${submission.pledge}</td>
                                            <td className='px-2 py-2 max-w-md  break-words text-sm text-gray-600'>{submission.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{submission.agentName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        )}
                        
                        {showAllsubmissions && filteredSubsubmissions.length === 0 && <div className='mt-2 text-red-500'>No results found</div>}

                        {!showAllsubmissions && !showWeek && !showMonth && searchBy === 'supplierCustomerId' && filteredSubsubmissions.length > 0 && (
                            
                            <div id='id-table' className='max-w-full'>
                                <ul  className='bg-white container flex gap-2 flex-wrap  border-blue-800 p-1 border-2 rounded-lg my-2'>
                                    {filteredSubsubmissions.map(submission => (
                                        <li
                                            key={submission._id}
                                            className='cursor-pointer w-max p-2 hover:bg-blue-200 rounded-lg'
                                            onClick={() => handleSubmissionSelect(submission._id)}
                                        >
                                            {submission.supplierCustomerId}
                                        </li>
                                    ))}
                                </ul>

                                {!showAllsubmissions && selectedSaleId && searchBy === 'supplierCustomerId' && (
                                    <SupplierSearchForm
                                        selectedSale={submissions.find(submission => submission._id === selectedSaleId)}
                                    />
                                )}
                            </div>
                        )}

                        {!showAllsubmissions && searchBy === 'agentName' && searchTerm && (
                            <div className='max-w-full'>
                                <ul className='bg-white container flex flex-wrap gap-2  border-blue-800 p-1 border-2 rounded-lg my-2'>
                                    {filteredSubAgents.map(agentName => (
                                        <li
                                            key={agentName}
                                            className='cursor-pointer  w-max p-2 hover:bg-blue-200 rounded-lg'
                                            onClick={() => handleAgentSubSelect(agentName)}
                                        >
                                            {agentName}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!showAllsubmissions && selectedAgent && searchBy === 'agentName' && filteredSubsubmissions.length > 0 && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table' className='min-w-full divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSubsubmissions.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {showWeek && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table all-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSubsubmissions.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>
                                                <input
                                                    type="checkbox"
                                                    id="paid"
                                                    name="paid"
                                                    checked={sale.paid || false}  // Check if paid is true
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {showMonth  && (
                            <div className='overflow-x-scroll'>
                            <table id='agent-table all-table' className='min-w-full overflow-x-scroll divide-y divide-gray-200 my-5'>
                                <thead className='bg-blue-50'>
                                    <tr>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Supplier Customer ID</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Date</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Customer Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Raffle Type</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Pledge</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Outcome</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Agent Name</th>
                                        <th className='px-2 py-2 text-center text-xs font-medium text-slate-700 uppercase tracking-wider'>Paid?</th>

                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    {filteredSubsubmissions.map(sale => (
                                        <tr key={sale._id}>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.supplierCustomerId}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{new Date(sale.submitDate).toLocaleDateString()}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.firstName} {sale.lastName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.raffleType ? 'VIP' : 'One Time Payment'}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>${sale.pledge}</td>
                                            <td className='px-2 py-2 max-w-md sm:min-w-lg  break-words text-sm text-gray-600'>{sale.outcome}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>{sale.agentName}</td>
                                            <td className='px-2 py-2 whitespace-nowrap text-sm text-gray-600'>
                                                <input
                                                    type="checkbox"
                                                    id="paid"
                                                    name="paid"
                                                    checked={sale.paid || false}  // Check if paid is true
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>

                        )}

                        {filteredSubsubmissions.length === 0 && searchTerm && <div className='mt-2 text-red-500'>No results found</div>}
                    </form>
                </div>
                <div className='container flex justify-between w-full text-center gap-4'>
                    <div className='container flex w-80 flex-col justify-center gap-1'>
                        <a
                            className='text-center hover:cursor-pointer container flex items-center justify-center font-reg py-1 text-xl text-black h-16 rounded-2xl bg-gradient-to-br from-slate-300 to-slate-400 hover:bg-gradient-to-lr hover:from-slate-400 hover:text-slate-900 hover:font-medium hover:to-slate-200 hover:shadow-lg hover:shadow-slate-700'
                            type="button"
                            href='/home'
                        >
                            Home
                        </a>
                        
                    </div>

                    <div className='container flex helv-med items-center'>
                        <div className='container flex flex-col'>
                            <h1>Show Last Weeks submissions</h1>
                            <input
                            type='checkbox'
                            checked={showWeek}
                            onChange={() => handleShowWeek()}
                            />
                        </div>

                        <div className='container flex flex-col w-max'>
                            <h1>Show Last Months submissions</h1>
                            <input
                            type='checkbox'
                            checked={showMonth}
                            onChange={() => handleShowMonth()}

                            />
                        </div>
                    </div>

                    

                    <div className='w-1/4'>
                    

                    <button 
                        onClick={exportToExcel} 
                        className="text-white m-1 mt-2 py-2 px-2 bg-green-600 rounded-lg w-max"
                    >
                        Export to Excel
                    </button>
            </div>
                </div>
            </div>
    )
}