// src/pages/EmailConfirmation.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const EmailConfirmation = () => {
    const [message, setMessage] = useState('Verifying...');
    const location = useLocation();
    const navigate = useNavigate();


    const signIn = (e) => {
        navigate('/')
    }

    useEffect(() => {
        // Extract token and email from query parameters
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const email = queryParams.get('email');

        // Verify the email by sending a request to the backend
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/confirm-email?token=${token}&email=${email}`)
        .then(response => {
            setMessage(response.data.message || 'Your email has been successfully verified!');
        })
        .catch(error => {
            console.error('Error details:', error.response ? error.response.data : error.message);
            setMessage('There was an issue verifying your email. Please try again later.');
        });
    }, [location.search]);

    return (
        <div className="container flex flex-col bg-gradient-to-br from-slate-950 to-purple-950 min-w-full min-h-screen justify-center items-center">
            <div className='w-1/2 bg-slate-300 rounded-2xl container  flex flex-col justify-center items-center p-4 content-center gap-2'>
            <h1 className="text-3xl font-bold ">Email Confirmation</h1>
            <p className="text-lg">{message}</p>
            <button onClick={signIn} className='text-xl font-bold text-purple-600 hover:text-purple-900'>Sign In!</button>
            </div>
        </div>
    );
};

export default EmailConfirmation;
